import 'nodelist-foreach-polyfill';

import '../../common/components/hamburger';
import Swiper, { Pagination } from 'swiper';
import amplitude from "../../common/components/amplitude";
import { isInViewport } from "../../common/components/isInViewport";
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
import { checkVisitIdInQuery } from "../../common/user-visit-warden";
import { executeSafely } from "../../common/components/executeSafety";
import { logImgLoadError } from "../../common/components/ImglogError";
import { translate } from "../../common/components/translator";

document.addEventListener('DOMContentLoaded', () => {
    init();

    // eslint-disable-next-line no-unused-vars
    const reviewSlider = new Swiper('.root__users', {
        modules: [Pagination],
        spaceBetween: 16,
        slidesPerView: 1.5,
        simulateTouch: false,
        scrollbarDraggable: true,
        loop: true,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination-users',
            clickable: true,
        },
        breakpoints: {
            1366: {
                loop: false,
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
    });
    // eslint-disable-next-line no-unused-vars
    const commandsSlider = new Swiper('.root__plan-comm-slider', {
        spaceBetween: 16,
        slidesPerView: 2.5,
        loop: false,
    });
    const buttonView = document.querySelector('#viewportAnchor');

    const updateCounterOnce = (() => {
        let called = false;

        return () => {
            if (!called) {
                const intervalId = setInterval(updateCounter, 20);
                const counterSelector = document.querySelector('.root__statistic-value');
                let count = 0;

                function updateCounter() {
                    const countTarget = parseInt(counterSelector.getAttribute('data-count'), 10);

                    if (count < countTarget) {
                        counterSelector.innerHTML = count + '%';
                    } else {
                        counterSelector.innerHTML = countTarget + '%';
                        clearInterval(intervalId);
                    }

                    count++;
                }

                called = true;
            }
        };
    })();

    function initAmplitude() {
        const queryParams = new URLSearchParams(window.location.search);

        const indentifyData = new amplitude.Identify()
            .set('quiz_url', window.location.pathname)
            .set('source', queryParams.has('fbclid') ? 'fb' : 'none')
            .set('test', queryParams.has('test') ? 'creative' : 'none')
            .set('visit_params', String(queryParams).replace('?', ''))
            .set('campaign', queryParams.has('utm_campaign') ? 'utm_campaign' : 'none')
            .set('adset', queryParams.has('utm_content') ? 'utm_content' : 'none')
            .set('ad', queryParams.has('ad.id') ? queryParams.get('ad.id') : 'none')
            .set('campaign_name', queryParams.has('campaign_name') !== -1 ? 'campaign_name' : 'none')
            .set('adset_name', queryParams.has('adset_name') !== -1 ? 'adset_name' : 'none')
            .set('ad_name', queryParams.has('ad_name') !== -1 ? 'ad_name' : 'none');

        amplitude.identify(indentifyData);
        amplitude.logEvent("Main page view");
    }

    function sendEventTakeQuiz() {
        const takeQuizBtn = document.querySelectorAll('.takeQuiz');

        takeQuizBtn.forEach((el) => {
            el.addEventListener('click', () => {
                amplitude.logEvent("Take quiz");
            });
        });
    }

    function sendEventRootLogin() {
        const takeQuizBtn = document.getElementById('rootLogin');

        takeQuizBtn.addEventListener('click', () => {
            amplitude.logEvent("Root Login");
        });
    }

    function sendEventLogIn() {
        const logInBtn = document.getElementById('logInBtn');

        if (!logInBtn) {
            return;
        }

        logInBtn.addEventListener('click', () => {
            amplitude.logEvent("Log in");
        });
    }

    function setLocalStorageParamIfUserFromRootDomain() {
        localStorage.setItem('Root', 'yes');
    }

    localStorage.setItem('OfferText', 'true');

    // Call update counter once
    window.addEventListener('scroll', () => {
        if (isInViewport(buttonView)) {
            updateCounterOnce();
        }
    });

    function initAccordion(accordionItemSelector, accordionHeadingSelector, openClass, uniqueAccordionID) {
        const accordionEl = document.getElementById(uniqueAccordionID);
        const accordionItems = accordionEl.querySelectorAll(accordionItemSelector);
        const accordionHeadings = accordionEl.querySelectorAll(accordionHeadingSelector);

        if (!accordionItems.length || !accordionHeadings.length) {
            console.error('Accordion items or headings are not found');
            return;
        }

        const openItem = (event) => {
            const currentItem = event.currentTarget.parentNode;

            accordionItems.forEach((item) => {
                if (item === currentItem) {
                    item.classList.toggle(openClass);
                } else {
                    item.classList.remove(openClass);
                }
            });
        };

        accordionHeadings.forEach((heading) => {
            heading.addEventListener('click', openItem);
        });
    }

    function initShowHideContent() {
        const toggleContentBtn = document.getElementById("toggleButton");
        const hiddenContent = document.getElementById("hiddenContent");

        if (!toggleContentBtn || !hiddenContent) {
            return;
        }

        const btnTextSelector = toggleContentBtn.querySelector('.root__info-why-btn-text');
        const btnArrowSelector = toggleContentBtn.querySelector('.root__info-why-btn-arrow');
        const gradientSelector = document.getElementById('gradientSelector');

        if (!btnTextSelector || !btnArrowSelector || !gradientSelector) {
            return;
        }

        const urlParams = new URLSearchParams(window.location.search);
        const lang = urlParams.get('lang').toLowerCase();

        toggleContentBtn.addEventListener("click", function () {
            if (hiddenContent.classList.contains("hidden")) {
                hiddenContent.classList.remove("hidden");
                btnTextSelector.textContent = translate("Show less", lang);
                btnArrowSelector.classList.add('up');
                gradientSelector.classList.remove('gradient-white');
            } else {
                const screenOffsetBottom = document.documentElement.scrollHeight - window.scrollY;

                hiddenContent.classList.add("hidden");
                btnTextSelector.textContent = translate("Show more", lang);
                btnArrowSelector.classList.remove('up');
                gradientSelector.classList.add('gradient-white');

                window.scrollTo({
                    top: document.documentElement.scrollHeight - screenOffsetBottom,
                })
            }
        });
    }

    function addEventListenerToAppButton() {
        const appButton = document.getElementById('app-button');

        appButton?.addEventListener('click', () => amplitude.logEvent('App button click'));
    }

    function initNativeAppInstallPrompt() {
        if (window.deviceName !== 'android') {
            return;
        }

        const installBtn = document.querySelector('.root__btn-app');

        if (!installBtn) {
            return;
        }

        let deferredPrompt;

        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            deferredPrompt = event;
        });

        installBtn.addEventListener('click', async () => {
            if (deferredPrompt) {
                deferredPrompt.prompt();
                deferredPrompt = null;
            }
        });
    }

    function init() {
        executeSafely(initAmplitude);
        executeSafely(sendEventTakeQuiz);
        executeSafely(sendEventLogIn);
        executeSafely(sendEventRootLogin);
        executeSafely(setLocalStorageParamIfUserFromRootDomain);
        executeSafely(checkVisitIdInQuery);
        executeSafely(logImgLoadError);
        executeSafely(initShowHideContent);
        executeSafely(initAccordion, '.root__accordion-item', '.root__accordion-item-heading', 'open', 'rootAccordion1');
        executeSafely(initNativeAppInstallPrompt);
        executeSafely(addEventListenerToAppButton);
    }
});
